import { formatPhoneNumber } from "@/utils/helpers/formatPhoneNumber";
import { useContactPhoneNumbersWithTypesAndCaps } from "@/utils/hooks/useContactPhoneNumbers";
import { useContacts } from "@/utils/hooks/useContacts";
import { colors } from "../../styles/global.styles";
import WebGwContact from "../../utils/helpers/WebGwContact";
import { Avatar } from "../shared/Avatar";
import HighlightedText from "../shared/HighlightedText";
import { ListItem } from "../shared/ListItem.style";
import { TextButton } from "../shared/TextButton";
import { logo, name } from "./ContactCard.style";

type BlockedContactCardProps = React.ComponentProps<"li"> & {
  contact: WebGwContact;
  toggleBlockModal: (contact: WebGwContact) => void;
};

const BlockedContactCard = ({
  contact: receivedContact,
  toggleBlockModal,
  ...liProps
}: BlockedContactCardProps) => {
  const contacts = useContacts();
  const contact =
    contacts?.find((pContact) => pContact.id === receivedContact?.id) ??
    receivedContact;

  const { data: phoneNumbers, isLoading } =
    useContactPhoneNumbersWithTypesAndCaps(contact?.id);
  const mainPhoneNumber = contact?.getMainPhoneNumber();

  const contactName = contact.noNameReturnPhoneNumber(
    receivedContact.userInputNumber
  );
  const mainPhoneNumberFormatted = mainPhoneNumber
    ? formatPhoneNumber(mainPhoneNumber, "E123")
    : undefined;

  return (
    <>
      {isLoading ? (
        <ListItem {...liProps}>{/* Skeleton code */}</ListItem>
      ) : (
        phoneNumbers && (
          <ListItem {...liProps}>
            <Avatar contact={contact} />
            <div>
              <HighlightedText
                css={name}
                text={contactName}
                highlightIndex={undefined}
                searchQueryLength={0}
              />
              {mainPhoneNumberFormatted && (
                <HighlightedText
                  css={[
                    name,
                    {
                      color: colors.secondaryTextColor,
                      marginTop: "0.25em",
                      fontSize: "0.8em",
                    },
                  ]}
                  text={mainPhoneNumberFormatted}
                  highlightIndex={undefined}
                  searchQueryLength={0}
                />
              )}
            </div>
            <div
              css={{
                width: "100%",
                height: "2em",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <div css={logo}>
                <TextButton
                  active={true}
                  onClick={() => toggleBlockModal(contact)}
                >
                  Unblock
                </TextButton>
              </div>
            </div>
          </ListItem>
        )
      )}
    </>
  );
};

export default BlockedContactCard;
